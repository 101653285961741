<script setup lang="ts">
import { card } from './cardStyles'

interface Props {
  padding?: boolean
}

const props = defineProps<Props>()

const theme = useRuntimeConfig().public.theme as keyof typeof card
const base = card[theme]

const ui = base({
  padding: props.padding,
})
</script>

<template>
  <div :class="ui">
    <slot />
  </div>
</template>
