import { tv } from 'tailwind-variants'

export const card = {
  alpha: tv({
    base: 'bg-brand-white border border-brand-gray-300 shadow-sm rounded-lg p-5 overflow-hidden',
    variants: {
      padding: {
        false: 'p-0',
      },
    },
  }),
  bravo: tv({
    base: 'bg-brand-white border border-brand-gray-300 shadow-sm rounded-lg p-5 overflow-hidden',
    variants: {
      padding: {
        false: 'p-0',
      },
    },
  }),
  charlie: tv({
    base: 'bg-brand-white border border-brand-gray-300 shadow-sm rounded p-5 overflow-hidden',
    variants: {
      padding: {
        false: 'p-0',
      },
    },
  }),
}
